<template>
  <div>
    <v-container grid-list-md fluid>
      <v-layout row wrap>
        <v-flex xs12 md12>
          <v-tabs centered slider-color="primary" v-model="tabs">
            <v-tab>Contratos</v-tab>
            <v-tab-item>
              <ContratosLista :autoriza_pag="currentUser.autoriza_pag" />
            </v-tab-item>
          </v-tabs>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>

const ContratosLista = () => import('./Contratos-List')
import { mapGetters } from 'vuex'

export default {
  name: 'ContratosTabs',
  components: {
    ContratosLista
  },
  data () {
    return {
      tabs: 0
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  }

}
</script>